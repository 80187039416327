$font-family-sans-serif: Inter, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #516376;
$gray-800: #3E4D5F;
$gray-900: #212529;
$black:    #000;

$blue:          #299FBA;
$indigo:        #4D43B3;
$purple:        #6f42c1;
$pink:          #d63384;
$red:           #CD0000;
$orange:        #AA5A10;
$yellow:        #ffc107;
$green:         #22C55E;
$brown:         #AA5A10;
$teal:          #20c997;
$cyan:          #1C5D6C;
$dark-gold:     #9A6A01;
$gold:          #E9A000;
$dark:          #1d1d1d;

$telegram:      #0088cc !default;
$facebook:      #4267B2 !default;
$twitter:       #1DA1F2 !default;
$linkedin:      #2867B2 !default;
$reddit:        #FF4500 !default;
$medium-com:    $white !default;
$youtube:       #FF0000 !default;
$vk:            #597da3 !default;
$instagram:     #c13584 !default;
$skype:         #00aff0 !default;
$viber:         #8f5db7 !default;
$whatsapp:      #25d366 !default;
$bitcoin-talk:  #F7931A !default;

$primary:       $gold;
$success:       $green;
$info:          $cyan;
$warning:       $brown;
$danger:        $red;
$light:         $gray-100;
$secondary:     $gray-500;

//$link-color:       #CCA93A;
$link-hover-color: #F6E494;

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "gray":       $gray-700,
  "dark":       $dark,
  "pink":       $pink,
  "cyan":       $cyan,
  "orange":     $orange,
  "blue":       $blue,
  "purple":     $purple,
  "black":      $black,
);

$body-bg: $black;
$body-color: $white;

$text-muted:    $gray-800;

$border-width: 0;
$border-color: $primary;

$spacer: 1rem;
//$border-width: 2px;
//
//$body-bg: $dark;
//$body-color: $white;
$modal-content-bg: $dark;
$modal-content-color: $body-color;
$modal-content-border-width: 0;
$modal-content-border-color: $primary;
$modal-header-border-color: $primary;
//$modal-header-padding-y: $spacer * 1.5;
//$modal-header-padding-x: $spacer * 2.5;
//$modal-inner-padding: $spacer * 2.5;
//
//$font-weight-lighter:         lighter;
//$font-weight-light:           300;
//$font-weight-normal:          400;
$font-weight-medium:          500 !default;
//$font-weight-semibold:        600;
//$font-weight-bold:            700;
//$font-weight-bolder:          800;
//
//$font-weight-base:            $font-weight-semibold;
//
//$enable-shadows: false;
//
//$border-radius: 1.5rem;
//$border-radius-sm: $border-radius * 0.75;
//$border-radius-lg: $border-radius * 1.25;
$border-radius:               2rem;
$border-radius-sm:            1rem;
$border-radius-lg:            3rem;
$border-radius-xl:            5rem;
$border-radius-2xl:           8rem;
$border-radius-pill:          50rem;

$btn-border-width: 1px;
$btn-padding-y:         .75rem;
$btn-padding-x:         1.75rem;
$btn-padding-y-lg:      1rem;
$btn-padding-x-lg:      2.25rem;
$btn-close-color: $white;
//$btn-font-weight: $font-weight-base;
$btn-border-radius:           $border-radius;
$btn-border-radius-sm:        $border-radius-sm;
$btn-border-radius-lg:        $border-radius-lg;
$input-border-radius:         $border-radius-lg;
$input-border-radius-sm:      $border-radius;
$input-border-radius-lg:      $border-radius-lg;
//$input-disabled-bg:           $gray-800;
$input-btn-padding-y:         .8rem;
$input-btn-padding-x:         1.5rem;
$input-bg:                    $black;
$input-border-color:          $primary;
$input-border-width:          $border-width;
$input-color:                 $primary;

$input-padding-y:                       $input-btn-padding-y;
$input-padding-x:                       $input-btn-padding-x;

// scss-docs-start input-group-variables
$input-group-addon-color:               $primary;
$input-group-addon-bg:                  $black;
// scss-docs-end input-group-variables

//$global-padding: 1.25rem;

$card-bg: $primary;
$card-spacer-y: $spacer * 3;
$card-spacer-x: $spacer * 3;
$card-border-width: 0;
$card-border-radius: $border-radius;
$card-color: $white;

// Progress bars

// scss-docs-start progress-variables
$progress-height:                   50px;
$progress-bg:                       $dark;
$progress-border-radius:            10px;
$progress-box-shadow:               none;
$progress-bar-color:                $dark;
$progress-bar-bg:                   $dark;
$progress-bar-transition:           all .6s ease;
// scss-docs-end progress-variables

$table-striped-bg-factor:     .25;
$table-striped-bg:            rgba($gray-700, $table-striped-bg-factor);

$table-hover-color:           $dark;
$table-hover-bg:              $primary;

$table-border-width:          0;
$table-cell-padding-y:        1.5rem;
$table-cell-padding-x:        1rem;

// scss-docs-start container-max-widths
$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1440px
);
// scss-docs-end container-max-widths

$table-color:                 $white;
