body, html, #root {
  height: 100%;
}

//#root {
//  background: linear-gradient(135deg, rgba($primary, 0.15) 0%, $body-bg 30%, $body-bg 70%, rgba($primary, 0.1) 100%);
//}

svg,
img {
  max-width: 100%;
  height: auto;
}

.table-switch {
  background: $dark;
  border-radius: $border-radius-lg;
  padding: 5px;
  cursor: pointer;

  span {
    font-size: 1rem;
    color: $primary;
    padding: 0.25rem 0.5rem;
    border-radius: $border-radius;

    &.is-active {
      background: linear-gradient(90deg, $dark-gold, $gold);
      color: $dark;
    }
  }
}

.preloader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  backdrop-filter: blur(3px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  z-index: 100;
}

.cur-pointer {
  cursor: pointer;
}

.rounded-1px {border-radius: 1px}
.rounded-2px {border-radius: 2px}
.rounded-3px {border-radius: 3px}
.rounded-4px {border-radius: 4px}
.rounded-5px {border-radius: 5px}
.rounded-6px {border-radius: 6px}
.rounded-7px {border-radius: 7px}
.rounded-8px {border-radius: 8px}
.rounded-9px {border-radius: 9px}
.rounded-10px {border-radius: 10px}

.progress-bar-bg {
  opacity: 0.2;

  &.bg-primary {
    background: rgb(81, 99, 118) !important;
    opacity: 0.7;
  }
}

.border-solid {border-style: solid}

.btn {
  &.active {
    color: $primary;
    cursor: default;
  }
}

.progress {
  border: 2px solid $dark;
}

.btn-primary {
  color: $dark;
}

.language-switcher {
  button {
    @extend .btn;
    @extend .btn-link;
    text-transform: uppercase;
    text-decoration: none;
    border-radius: 0;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 1;
    color: $white;
    box-shadow: none !important;
    font-weight: $font-weight-medium;

    &:hover,
    &.active {
      color: $primary;
      text-decoration: none;
    }

    &.active {
      font-weight: $font-weight-bold;
    }
  }

  .language-switcher-list {
    background: $gray-900;
    overflow: hidden;
    padding-top: 0;
    padding-bottom: 0;
    min-width: 160px;
    border-radius: 1rem;
    float: right;
    margin-left: -2.5rem !important;
    border: 1px solid $gray-900;

    .dropdown-item {
      color: $white;
    }

    .dropdown-item:hover {
      background: $gray-800;
    }
  }
}

.card-icon {
  position: absolute;
  bottom: -45px;
  right: -45px;
  z-index: 1;
  width: 200px;

  &.icon-referral {
    bottom: 0;
    right: 0;
  }
}

.wallet-wave {
  position: absolute;
  bottom: 0;
  right: 0;
}

.wallet-coin {
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: 110px;
  height: auto;
}

@media (min-width: 480px) {
  .wallet-coin {
    width: 140px;
    right: 15px;
  }
}

.wallet-gold {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 240px;
  height: auto;
}

.card {
  .nav {
    .nav-item {
      &:first-child {
        .nav-link {
          margin-left: 0 !important;
        }
      }
      .nav-link:not(.tx-gray-600) {
        color: $white;
      }
      .nav-link {
        position: relative;

        &.active:not(.text-decoration-none) {
          &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            border-radius: 5px;
            background: $white;
          }
        }
      }
    }
    &.nav-levels {
      .nav-link {
        padding: 5px;
        width: 40px;
        height: 40px;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-width: 0;

        &.active {
          background: $dark-gold;
          color: $white;
          &:after {
            display: none;
          }
        }
      }

      &.invest-plans {
        .nav-link {
          height: 36px;
        }
      }
    }
  }

  &.waves-bg {
    background: url('../assets/images/wallet-wave.svg') right bottom no-repeat;
  }

  > .card-heading {
    height: 26px;
  }
}

a.card {
  text-decoration: none;
  div {
    text-decoration: underline;
  }

  .card-icon {
    transition: all .5s ease;
  }

  &:hover,
  &:focus {
    color: $white;

    .card-icon.icon-referral {
      transform: scale(1.15) translateX(-20px);
    }
    .card-icon.icon-history {
      transform: rotate(25deg) translateX(-10px);
    }
    .card-icon:not(.icon-referral):not(.icon-history) {
      transform: translate(-40px, -40px) rotate(15deg);
    }
  }
}

a {
  &:focus,
  &:hover {
    text-decoration: none;
  }
}

a.tx-dark {
  &:focus,
  &:hover {
    color: $dark;
    text-decoration: none;
  }
}

.card {
  .card-absolute {
    width: 180px;
    height: 100%;
    position: absolute;
    top: 0;
    right: -2px;
  }
}

.progress {
  &.progress-vertical {
    flex-direction: column;
    justify-content: end;
    width: 50px;
    height: 100%;

    .progress-bar {
      width: $progress-height !important;
      height: auto;
    }
  }
}

.svg-inline--fa {
  &.interactive {
    cursor: pointer;
    transition: opacity 300ms ease-in;

    &:hover {
      opacity: 0.7;
    }
  }
}

.footer-link {
  color: #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  &:hover {
    color: #fff;
  }
  svg {
    width: 20px!important;
  }
}
