.gradient-primary {
  background: linear-gradient(90deg, $dark-gold, $gold);
}

.gradient-primary-down {
  background: linear-gradient(180deg, $dark-gold, $gold);
}

.gradient-primary-135deg {
  background: linear-gradient(135deg, $dark-gold, $gold);
}
