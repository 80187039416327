@mixin table-shadow() {
  position: relative;
  background-color: $dark !important;
  background-clip: border-box;
  border-radius: $border-radius;

  .table {
    tbody {
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
      tr {
        background: linear-gradient(90deg, $dark-gold, $gold) !important;
        &:first-child {
          td {
            position: relative;
          }
          td:first-child,
          td:last-child {
            &:before,
            &:after {
              position: absolute;
              transition: all .15s ease-in-out;
              height: $border-radius * 2;
              width: $border-radius;
              box-shadow: 0 ($border-radius * -1) 0 0 $dark;
            }
          }
          td:first-child {
            &:before {
              content: "";
              top: 0;
              left: 0;
              border-top-left-radius: $border-radius;
            }
          }
          td:last-child {
            &:after {
              content: "";
              top: 0;
              right: 0;
              border-top-right-radius: $border-radius;
            }
          }
        }
      }
    }
  }

  @if $enable-shadows {
    //@include box-shadow($btn-box-shadow, 0 10px 30px 5px rgba($black, .85));
  }
}

@mixin table-list-view() {
  .table {
    display: block;

    thead {
      display: none;
    }
    tbody {
      display: flex;
      flex-direction: column;
    }

    tr {
      @include table-shadow();

      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin-bottom: 2rem;
      background: linear-gradient(90deg, $dark-gold, $gold) !important;

      td {
        border-top: none;
        border-bottom: none;
        flex-basis: auto;
      }

      @include media-breakpoint-up(md) {
        flex-direction: row;
        td {
          flex-basis: 50%;
        }
      }

      @include media-breakpoint-up(lg) {
        td {
          flex-basis: 33.3%;
        }
      }

      @include media-breakpoint-up(xxxl) {
        td {
          flex-basis: 25%;
        }
      }
    }
  }
}

.table-wrapper {
  overflow-y: hidden;
  overflow-x: scroll;

  @include table-shadow();
}

.table-wrapper-responsive {
  position: relative;
  @include border-radius($card-border-radius);

  .list-view-heading {
    font-weight: bold;
  }

  &:not(.list-view) {
    @include media-breakpoint-up(lg) {
      overflow-y: hidden;
      overflow-x: scroll;

      .list-view-heading {
        display: none;
      }

      @include table-shadow();
    }
  }

  &.list-view {
    @include table-list-view();
  }

  @include media-breakpoint-down(lg) {
    @include table-list-view();

    .table td {
      padding: .25rem .75rem;
    }
  }
}

.table > tbody > tr:last-child > td {
  border-bottom: none;
}

.table.table-hover > tbody > tr:hover {
  .tx-primary {
    color: $dark !important;
  }
}
