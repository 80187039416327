#main-content {
  width: 100%;
  height: 100%;
  background: $body-bg;


  &.logged-out {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }
}

#under-construction-content {
  width: 100%;
  height: 50%;
  min-height: 300px;
  background: url('../../assets/images/under-construction-bg.jpg') center center;
  background-size: cover;
}

.hamburger {
  z-index: 1;
  width: 45px;
  height: 35px;
  cursor: pointer;
  transition: box-shadow 0.4s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  align-items: center;
  justify-content: center;
  display: flex;
  background: none;
  border: none;
  outline: none;
  padding: 0 .5em;

  @include media-breakpoint-up(lg) {
    display: flex;
  }

  > div {
    position: relative;
    flex: none;
    width: 100%;
    height: 2px;
    background: $primary;
    transition: all 0.4s ease;
    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
      content: '';
      position: absolute;
      z-index: 1;
      top: -10px;
      left: 0;
      width: 100%;
      height: 2px;
      background: inherit;
      transition: all 0.4s ease;
    }

    &:after {
      content: '';
      position: absolute;
      z-index: 1;
      left: 0;
      width: 100%;
      height: 2px;
      background: inherit;
      transition: all 0.4s ease;
      top: 10px;
    }
  }
}

.open-nav {
  @include media-breakpoint-down(lg) {
    overflow: hidden;
  }

  #topbar {
    background: rgba($dark, 0.05);
  }

  .hamburger > div {
    &:before {
      top: 0;
      transform: rotate(90deg);
    }

    &:after {
      top: 0;
      transform: rotate(90deg);
      opacity: 0;
    }

    transform: rotate(135deg);
  }

  .overlay {
    display: block;
    position: fixed;
    padding: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.8);
    backdrop-filter: blur(5px);
    z-index: 99;

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  #sidebar {
    left: 0;
  }
}
