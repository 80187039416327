#referral-sub-lines {

  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media-breakpoint-down(lg) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 10px;
    }
  }

  .action {
    font-size: 14px;
  }
}
